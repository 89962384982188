/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import styled from '@emotion/styled';
import { Arrow } from '../assets/VectorAssets';
import media from './media';

export const NEXT = 'NEXT';
export const PREV = 'PREV';

export const Item = styled.div`
  text-align: center;
  /* padding: 100px; */
  
  height: 224px;
  /* @412 */
  ${media.sm`
    height: 304px;
  `}
  /* @768 */
  ${media.md`
    height: 376px;
  `}
  /* @1024 */
  ${media.lg`
    height: 424px;
  `}
  /* @1024 */
  ${media.xl`
    height: 492px;
  `}
  
  background-image: ${props => `url(${props.img})`};
  background-size: cover;
  background-position: center center;
`;

export const CarouselContainer = styled.div`
  display: flex;
  transition: ${props => (props.sliding ? 'none' : 'transform 1s ease')};
  transform: ${props => {
    if (!props.sliding) return 'translateX(calc(-80% - 20px))';
    if (props.dir === PREV) return 'translateX(calc(2 * (-80% - 20px)))';
    return 'translateX(0%)';
  }};
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
`;

export const CarouselSlot = styled.div`
  flex: 1 0 100%;
  flex-basis: 80%;
  margin-right: 4px;
  order: ${props => props.order};
`;

export const ButtonWrapper = styled.div`
  padding: 1rem 0 0;
  /* background: blue; */
  display: flex;
  justify-content: flex-start;
  /* find a better way to do this */
  button + button {
    margin-left: 1rem;
  }
`;
