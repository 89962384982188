/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import styled from '@emotion/styled';
import { Arrow } from '../assets/VectorAssets';

export const StyledArrowButton = styled.button`
  background: transparent;
  cursor: pointer;
  border: none;
  padding: 0.5rem 0;
  line-height: 1;
  display: flex;
  align-items: center;

  &:focus {
    outline: 0;
    fill: initial;
  }

  &:hover svg path {
    transition: 0.2s ease fill;
    fill: ${({ theme }) => theme.colors.secondary};
  }
  /* Flip The arrow */
  svg {
    transform: ${props => (props.flip ? 'scale(-1,1)' : 'none')};
  }

  /* reset color on touch devices */
  @media (hover: none) {
    svg path {
      fill: initial;
    }
  }
`;

const ArrowButton = props => (
  <StyledArrowButton flip={props.flip} sx={{ variant: 'text.h4' }} onClick={props.onClick}>
    {!props.flip && props.children}
    {/* hacky conditional padding */}
    {!props.flip && props.children && <span sx={{ width: '20px' }} />}
    <Arrow />
    {props.flip && props.children && <span sx={{ width: '20px' }} />}
    {props.flip && props.children}
  </StyledArrowButton>
);

export default ArrowButton;
