/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React, { useRef, useState } from 'react';
import Nuka, { PagingDots } from 'nuka-carousel';
import { useWindowSize } from 'react-use';
import Image from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { Item } from './SliderComponents';
import Carousel from './Carousel';

import ArrowButton, { StyledArrowButton } from './ArrowButton';
import { Arrow } from '../assets/VectorAssets';

export const NukaArrows = ({ previousSlide, nextSlide }) => (
  <div
    sx={{
      position: 'absolute',
      left: '0',
      top: '100%',
      bottom: 'initial',
      // bg: 'debug',
      mt: '1em',
      display: 'flex',
    }}
  >
    <StyledArrowButton flip sx={{ mr: '1em' }} onClick={previousSlide}>
      <Arrow />
    </StyledArrowButton>
    <StyledArrowButton sx={{}} onClick={nextSlide}>
      <Arrow />
    </StyledArrowButton>
  </div>
);

const NukaDots = props => (
  <div
    sx={{
      // bg: 'green',
      top: '100%',
      bottom: 'initial',
      transform: 'translateY(calc(100% + 1em))',
      ul: {
        top: '0!important',
        button: {
          py: '15px',
          lineHeight: '1',
          fontSize: '6px',
        },
      },
    }}
  >
    <PagingDots {...props} />
  </div>
);

export const NukaComposed = () => {
  // better way to query for this
  // https://tylergaw.com/articles/reacting-to-media-queries-in-javascript/
  const { width, height } = useWindowSize();

  // Get the images
  const data = useStaticQuery(graphql`
    query NukaSliderQuery {
      allFile(
        filter: { relativeDirectory: { eq: "slider" }, sourceInstanceName: { eq: "asset" } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            id
            name
            sourceInstanceName
            relativeDirectory
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  const { edges: sliderImages } = data.allFile;

  return (
    <Nuka
      slidesToShow={1.25}
      wrapAround
      cellSpacing={4}
      autoplay
      enableKeyboardControls
      // rip out default controls
      renderCenterLeftControls={() => null}
      renderCenterRightControls={() => null}
      renderBottomCenterControls={() => null}
      sx={{
        width: ['100vw!important', '100vw!important', '90vw!important'],
      }}
    >
      {sliderImages.map(({ node }, i) => (
        <Image key={node.id} fluid={node.childImageSharp.fluid} alt={`Gatsby Image #${i + 1}`} />
      ))}
    </Nuka>
  );
};

// export default ComposedCarousel;
